<template>

    <modal ref="modal" :show="show" @onClose="close" :saveDisabled="false">
        <template #title>
            Order wisselen
        </template>

        <div class="alert alert--success mt-3" v-if="this.$store.state.callId">
            <i class="far fa-phone mr-2"></i>
            <p class="mb-0 d-inline-block mr-2">
                Wanneer je deze order opslaat, wordt hij gekoppeld aan het telefoongesprek.
            </p>
            <a class="btn btn-white btn-sm" @click="$store.dispatch('cancelCallId', null)">Annuleren</a>
        </div>

        <div v-if="mainOrderItem">
            <order-item-teaser :with-customer="true" :order="order" :order-item="Object.assign({}, mainOrderItem)"></order-item-teaser>
            <div v-if="!loading">
                <div class="box-card box-card--single">
                    <div class="box-card-content">
                        <h3>Levergegevens</h3>
                        <div class="form-row">

                            <form-control class="col form-item--compact"
                                          name="preferred_delivery_at"
                                          :edit="$store.getters.isEditable"
                                          label="Wisseldatum"
                                          :value="order.preferred_pickup_at_formatted ? order.preferred_pickup_at_formatted  :  'Onbekend'">
                                <template v-slot:input>
                                    <input v-model="deliveryAt"
                                           class="form-control js-datepicker"
                                           type="text">
                                </template>
                            </form-control>
                        </div>
                        <div class="form-row">
                            <form-control class="col form-item--compact"
                                          name="delivery_preference_id"
                                          :edit="$store.getters.isEditable"
                                          label="Voorkeur dagdeel wisselen"
                                          :value="order.delivery_preference_formatted ? order.delivery_preference_formatted :  'Onbekend'">
                                <template v-slot:input>
                                    <select class="form-control" type="date" v-model="deliveryPreferenceId">
                                        <option :value="option.id" v-for="option in $store.state.deliveryPreferences" v-text="option.name">
                                        </option>
                                    </select>
                                </template>
                            </form-control>
                        </div>
                        <div class="form-row">
                            <form-control class="col form-item--compact"
                                          name="phone_number_id"
                                          label="Bel naar telefoonnummer 30 min. voor leveren"
                                          :edit="$store.getters.isEditable">
                                <template #input>
                                    <div class="form-item-select form-item-select--withAdd">
                                        <dropdown-select class="form-view" :initial-selected="callBeforePhoneNumber"
                                                         @onSelect="setPhoneNumber"
                                                         :preload="true"
                                                         :itemCallback="(item) => ({
                                                     id: item.id,
                                                     value: item.display,
                                                 })"
                                                         :url="'/company/api/sales/customers/' + order.customer.id + '/phone-numbers'">
                                        </dropdown-select>

                                        <div class="form-item-select-add" @click="onAddPhoneNumberClick()">
                                            <i class="far fa-plus-circle"></i>
                                        </div>
                                    </div>
                                </template>
                            </form-control>
                            <div class="col">
                                <form-control class="form-item--compact" :edit="true" name="payment_method_id"
                                              label="Betaalmethode *"
                                              :value="selectedPaymentMethodId ? getPaymentMethod().name : 'Onbekend'">
                                    <template v-slot:input>
                                        <select class="form-control" v-model="selectedPaymentMethodId">
                                            <option :value="option.id" v-text="option.name"
                                                    v-for="option in $store.state.paymentMethods">{{ option }}
                                            </option>
                                        </select>
                                    </template>
                                </form-control>

                                <div class="alert alert--warning mb-3" v-if="selectedPaymentMethodId && getPaymentMethod().direct_debit && !$store.state.order.customer.iban_account_number">
                                    Geen IBAN bekend bij deze klant.
                                </div>

                                <div class="alert alert-success mb-3" v-if="selectedPaymentMethodId && getPaymentMethod().direct_debit && $store.state.order.customer.iban_account_number">
                                    IBAN bekend: {{ $store.state.order.customer.iban_account_number }}
                                </div>
                            </div>
                        </div>


                        <div class="form-row">
                            <div class="col">
                                <div class="form-item form-item--compact">
                                    <label for="reference" class="control-label">Referentie</label>
                                    <input id="reference" class="form-control" type="text" v-model="projectReference">
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-item form-item--compact">
                                    <label for="notes" class="control-label">Opmerkingen</label>
                                    <textarea id="notes" class="form-control" v-model="notes"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="form-row">
                            <div class="col">
                                <div class="form-item form-item--compact">
                                    <label for="instructions" class="control-label">Opmerkingen leveren</label>
                                    <textarea id="instructions" class="form-control" v-model="delivery_instructions"></textarea>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-item form-item--compact">
                                    <label for="pickup_instructions" class="control-label">Opmerkingen ophalen</label>
                                    <textarea id="pickup_instructions" class="form-control" v-model="pickup_instructions"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="box-card box-card--single">
                    <div class="box-card-content">
                        <h3>Container wijzigen</h3>
                        <div class="product-image-container product-image-box--bottom">
                            <div v-if="selectedProduct.id" class="product-image-box">
                                <div class="product-image" :style="'background-image: url(' + selectedProduct.image_url + ')'"></div>
                            </div>
                            <div class="form-item form-item--compact form-item--compactInline">
                                <label class="control-label">Product</label>
                                <select class="form-control" :value="productId" @change="(event) => setProduct(event.target.value)">
                                    <option value="">Selecteer een product</option>
                                    <option :value="product.id" v-text="getProductName(product)"
                                            v-for="product in products"></option>
                                </select>
                            </div>
                        </div>

                        <div v-if="selectedProduct.id">
                            <div v-for="attribute in selectedProduct.price.price_attributes">
                                <h4>{{ attribute.name }} <span v-if="attributeIsRequired(attribute)">*</span><span v-if="attributeIsLocked(attribute)"><i class="fa fa-ban" data-toggle="tooltip" title="Dit attribuut is geblokkeerd voor bewerken omdat de levering al plaats heeft gevonden."></i></span></h4>
                                <table class="table table--noHeader">
                                    <tbody>
                                    <tr v-for="option in attribute.options" :class="attributeIsLocked(attribute) ? 'cursor-not-allowed' : 'pointer'" @click="selectOption(attribute, option, true)">
                                        <td>
                                            <input type="radio" :disabled="attributeIsLocked(attribute)" :checked="isChecked(attribute, option)">
                                        </td>
                                        <td>
                                            {{ option.name }}
                                        </td>
                                        <td>
                                            {{ option.price_incl | format_money }}
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="box-card box-card--single">
                    <div class="box-card-content">
                        <h3>Overzicht</h3>
                        <div class="form-row">
                            <div class="col">
                                <div class="form-item">
                                    <label data-toggle="tooltip" title="Prijs Incl. BTW" class="control-label">Totaalbedrag <i class="fa fa-info-circle"></i></label>
                                    <input class="form-control" type="text" v-model="customPriceIncl">
                                    <span v-if="hasVariationDiscount" class="text-muted">Automatisch ingevuld op basis van prijsvariaties</span>
                                </div>
                            </div>
                            <div class="col">
                                <div class="form-item">
                                    <label class="control-label">Korting</label>
                                    <input type="text" disabled class="form-control" :value="totalDiscountLabel()"/>
                                </div>
                            </div>
                        </div>
                        <div class="form-item" v-if="customPriceReason">
                            <label class="control-label">Reden</label>
                            <textarea disabled="disabled" class="form-control">{{ customPriceReason }}</textarea>
                        </div>
                        <div class="form-item" v-if="customPriceIncl">
                            <label class="control-label">Reden</label>
                            <div v-if="$store.state.customPriceTypes.length === 0"><a href="/custom-price-types">Geen redenen aangemaakt. Klik hier om deze aan te maken.</a></div>
                            <div class="form-check" v-for="customPriceType in $store.state.customPriceTypes">
                                <input class="form-check-input" type="radio" :id="'cp-reason-' + customPriceType.id" v-model="customPriceTypeId" :value="customPriceType.id">
                                <label class="form-check-label" :for="'cp-reason-' + customPriceType.id">
                                    {{ customPriceType.name }}
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="box-card box-card--single">
                    <div class="box-card-content">
                        <h3>Leveranciers</h3>

                        <table class="table">
                            <thead>
                            <tr>
                                <th><input type="checkbox" v-model="checkAllBox" @click="checkAll" class="mr-1" style="vertical-align: sub;"> Versturen?</th>
                                <th>#</th>
                                <th></th>
                                <th>Leverancier</th>
                                <th>Prijs</th>
                                <th>Marge</th>
                            </tr>
                            </thead>
                            <draggable v-if="hasRequiredAttributesSelected()" v-model="supplierRequests" group="supplier" tag="tbody">
                                <template v-for="(supplierRequest, i) in supplierRequests">
                                    <tr :key="supplierRequest.supplier.supplier_id">
                                        <td>
                                            <input type="checkbox" v-model="supplierRequest.should_send">
                                        </td>
                                        <td v-text="i + 1"></td>
                                        <td>
                                            <span data-toggle="tooltip" title="Heeft huidige container geplaatst." v-if="supplierRequest.supplier.id === order.supplier.id">
                                                <i class="far fa-fw fa-truck-container text-warning"></i>
                                            </span>
                                        </td>
                                        <td>
                                            <supplier-name :supplier="supplierRequest.supplier"/>
                                        </td>
                                        <td><span class="noWrap">{{ supplierRequest.price_excl | format_money }}</span><br><span class="noWrap">{{ supplierRequest.price_incl | format_money }}</span></td>
                                        <td>
                                            <div>
                                                <span v-if="getMargin(supplierRequest) >= 0" class="tag tag--large tag--success">{{ getMargin(supplierRequest) }}%</span>
                                                <span v-if="getMargin(supplierRequest) < 0" class="tag tag--large tag--danger">{{ getMargin(supplierRequest) }}%</span>
                                            </div>
                                        </td>
                                    </tr>
                                </template>

                            </draggable>
                            <tbody v-else>
                            <tr>
                                <td colspan="99"><span class="alert alert--orange">Selecteer eerst alle verplichte attributen.</span></td>
                            </tr>
                            </tbody>

                        </table>
                    </div>
                </div>

                <div class="alert alert--warning alert--large">
                    <p>
                        <strong>
                            Let op, door deze keuze worden de volgende zaken in gang gezet:
                        </strong>
                    </p>
                    <ul>
                        <li>
                            Klant krijgt factuur.
                        </li>
                        <template v-if="delivery_instructions && delivery_instructions !== ''">
                            <li>
                                Let op! De lever opmerkingen worden overgenomen. Controleer of deze nog van toepassing zijn.
                            </li>
                        </template>
                        <template v-if="pickup_instructions && pickup_instructions !== ''">
                            <li>
                                Let op! De ophaal opmerkingen worden overgenomen. Controleer of deze nog van toepassing zijn.
                            </li>
                        </template>
                        <template v-if="changedProduct && selectedProduct.id">
                            <li>
                                {{ mainOrderItem.product.name }} wordt opgehaald
                            </li>
                            <li>
                                {{ selectedProduct.name }} wordt geplaatst
                            </li>
                        </template>
                        <template v-else>
                            <li v-if="selectedProduct.id">
                                {{ selectedProduct.name }} wordt gewisseld
                            </li>
                        </template>


                        <template v-if="supplierRequests.length">
                            <template v-if="supplierRequests[0].supplier.id === order.supplier.id">
                                <li>
                                    {{ order.supplier.name }} krijgt een aanvraag voor wisseling.
                                </li>
                            </template>
                            <template v-else>
                                <li>
                                    {{ order.supplier.name }} krijgt een aanvraag voor ophaling
                                </li>
                                <li>
                                    {{ supplierRequests[0].supplier.name }} krijgt een aanvraag voor plaatsing
                                </li>
                                <li>
                                    Plaatsing zal een werkdag na ophaling plaatsvinden.
                                </li>
                            </template>
                        </template>

                    </ul>
                </div>

            </div>
            <div v-else style="min-height: 600px" class="w-100 d-flex justify-content-center align-items-center">
                <div><i class="fa text-info fa-spinner fa-spin fa-2x"></i></div>
            </div>
        </div>

        <template #footer>
            <div>
                <button type="button" @click="close" class="btn btn-default">
                    Annuleren
                </button>
                <loading-button
                    :disabled="saveDisabled"
                    childClass="btn btn-primary"
                    :loading="$store.state.disableOrderCreateButton"
                    @click.native="save"
                >
                    Wissel doorvoeren
                </loading-button>
            </div>
        </template>
    </modal>

</template>

<script>
import OutboundCallButton from "../../OutboundCallButton";
import Modal from "./Modal";
import OrderItemTeaser from "./OrderItemTeaser";
import FormControl from "./FormControl";
import draggable from 'vuedraggable'
import SupplierName from "./Supplier/SupplierName";
import orderItemMixin from "../../../mixins/orderItemMixin";


Date.prototype.toYMD = function () {
    var d = this.getDate();
    var m = this.getMonth() + 1; //Month from 0 to 11
    var y = this.getFullYear();
    return '' + y + '-' + (m <= 9 ? '0' + m : m) + '-' + (d <= 9 ? '0' + d : d);
}

export default {
    name: "SwapForm",
    props: ['show'],
    components: {
        SupplierName,
        OutboundCallButton,
        FormControl,
        OrderItemTeaser,
        Modal,
        draggable,
    },

    mixins: [orderItemMixin],
    data: () => ({
        productId: null,
        products: [],
        selectedProduct: {
            id: null,
            price: { price_attributes: [] }
        },
        selectedAttributes: [],
        quantity: 1,
        projectReference: '',
        callBeforePhoneNumber: null,
        notes: '',
        loading: false,
        loadingSuppliers: false,
        deliveryAt: null,
        delivery_instructions: null,
        pickup_instructions: null,
        mainOrderItem: null,
        supplierRequests: [],
        selectedPaymentMethodId: null,
        checkAllBox: false,
        customPriceIncl: null,
        customPriceReason: null,
        customPriceTypeId: null,
        variations: [],
        hasVariationDiscount: false,
    }),
    mounted(){
        window.PhoneNumberModal =  AdminModal.Instance();
    },
    computed: {
        order() {
            return this.$store.state.order;
        },
        saveDisabled() {
            if (this.deliveryAt === null) {
                return true;
            }

            if (this.selectedProduct.id === null) {
                return true;
            }

            if (this.selectedPaymentMethodId && this.getPaymentMethod().direct_debit && !this.order.customer.iban_account_number) {
                return true;
            }

            return !this.hasRequiredAttributesSelected();
        },
        changedProduct() {
            if (!this.mainOrderItem) {
                return false;
            }
            return this.mainOrderItem.product.id !== this.selectedProduct.id;
        },
    },
    updated() {
        $('[data-toggle="tooltip"]').tooltip();
    },

    methods: {
        checkAll() {
            this.supplierRequests.forEach((value, index) => {
                value.should_send = !this.checkAllBox;
            });
        },
        loadProducts() {
            this.loading = true;
            return axios.post('/company/api/sales/order/products', {
                webshop_id: this.order.webshop.id,
                customer_id: this.order.customer.id,
                preferred_delivery_at: (new Date()).toISOString(),
            }).then(res => {
                this.products = res.data.data;
                this.loading = false;
                return res;
            });
        },
        close() {
            this.productId = null;
            this.products = [];
            this.selectedProduct = {
                id: null,
                price: { price_attributes: [] }
            };
            this.selectedAttributes = [];
            this.quantity = 1;
            this.loading = false;
            this.mainOrderItem = null;
            this.customPriceIncl = null;
            this.customPriceReason = null;
            this.customPriceTypeId = null;
            this.callBeforePhoneNumber = null;
            this.$emit('close');
        },

        formatDate(date) {
            date = new Date(date);
            return date.toLocaleDateString('nl-NL')
        },
        getMargin(orderRequest) {
            let customerIncl = this.getAttributeTotalPrice();

            if (this.customPriceIncl) {
                customerIncl = this.customPriceIncl;
            }

            if (customerIncl === 0) {
                return 0;
            }

            let supplierIncl = orderRequest.calculated_price_incl;
            return (((customerIncl - parseFloat(supplierIncl)) / customerIncl) * 100).toFixed(2)
        },
        async save() {
            this.$store.dispatch('swapOrder', {
                preferred_delivery_at: this.deliveryAt,
                project_reference: this.projectReference,
                delivery_preference_id: this.deliveryPreferenceId,
                delivery_instructions: this.delivery_instructions,
                pickup_instructions: this.pickup_instructions,
                notes: this.notes,
                call_before_phone_number_id: this.callBeforePhoneNumber ? this.callBeforePhoneNumber.id : null,
                supplier_requests: this.supplierRequests,
                payment_method_id: this.selectedPaymentMethodId,
                call_id: this.$store.state.callId,
                custom_price_incl: this.customPriceIncl,
                custom_price_reason: this.customPriceReason,
                custom_price_type_id: this.customPriceTypeId,
                has_variation_discount: this.hasVariationDiscount,
                products: this.selectedAttributes.map(attribute => {
                    return {
                        price_product_attribute_option_id: attribute.option_id,
                        price_product_attribute_id: attribute.id
                    }
                })
            }).then(() => {
                this.close();
            })
        },
        initializeForm() {
            if (this.mainOrderItem) {
                this.loadProducts().then(() => {
                    this.setProduct(this.mainOrderItem.product.id);
                    this.quantity = this.mainOrderItem.quantity;
                    let date = new Date();
                    date.setDate(date.getDate() + 1)
                    this.deliveryAt = date.toYMD();

                    this.projectReference = this.order.project_reference;
                    this.delivery_instructions = this.order.delivery_instructions;
                    this.pickup_instructions = this.order.pickup_instructions;
                    this.notes = this.order.notes;
                    this.callBeforePhoneNumber = this.order.call_before_phone_number;
                    this.selectedPaymentMethodId = this.order.payment_method.id;
                });
            }
        },
        loadOrderRequests() {
            if (!this.deliveryAt || !this.selectedAttributes.length) {
                return;
            }
            if (!this.hasRequiredAttributesSelected()) {
                return;
            }
            this.loadingSuppliers = true;
            axios.post('/company/api/sales/orders/supplier/locations', {
                webshop_id: this.order.webshop.id,
                products: [
                    {
                        attributes: this.selectedAttributes.map(attribute => {
                            return {
                                price_product_attribute_option_id: attribute.option_id,
                                price_product_attribute_id: attribute.id
                            }
                        })
                    }
                ],
                date: this.deliveryAt,
                delivery_address_id: this.order.delivery_address.id,
            }).then(({ data }) => {
                this.supplierRequests = data.data;
                this.loadingSuppliers = false;
            })
        },
        getPaymentMethod() {
            return this.$store.state.paymentMethods.find(p => p.id === this.selectedPaymentMethodId);
        },
    },
    watch: {

        show: function (val) {
            if (val === true) {
                this.mainOrderItem = Object.assign({}, this.$store.state.order.order_items[0]);
                this.initializeForm();
                return;
            }
            this.mainOrderItem = null;
        },
        selectedAttributes: async function (val) {
            if (!val.length) {
                this.supplierRequests = [];
                return;
            }
            this.loadOrderRequests();

            this.hasVariationDiscount = false;
            if (!this.hasRequiredAttributesSelected()) {
                return;
            }

            const variationData = await this.$store.dispatch('getPriceVariations', { attributes: val, product_id: this.selectedProduct.id, address_id: this.order.delivery_address.id });
            const variationPrice = variationData.reduce((val, item) => val + item.price_incl, 0)

            this.variations = variationData;
            if (this.getAttributeTotalPrice() !== variationPrice) {
                this.customPriceIncl = variationPrice.toString();
                this.hasVariationDiscount = true;
            }
        },
        deliveryAt: function (val) {
            if (!val) {
                this.supplierRequests = [];
                return;
            }
            this.loadOrderRequests();
        }
    }
}
</script>
